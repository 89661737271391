@tailwind base;
@tailwind components;
@tailwind utilities;

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.logo {
  object-fit: contain;
  background-size: contain;
  background-position: contain;
  background-repeat: no-repeat;
  background-image: url("../img/logos/c9group.png");
}

@media (hover: hover) {
  .card:hover > .description {
    opacity: 1;
    padding-top: 2rem;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
}

.voluntarius-01 {
  background-image: url("../img/screenshots/voluntarius/01.png");
  top: -10;
  left: -10;
  transform: scale(0.9);
}

.voluntarius-02 {
  background-image: url("../img/screenshots/voluntarius/02.png");
  top: 20;
  left: 25%;
  transform: scale(0.9);
}

.voluntarius-03 {
  background-image: url("../img/screenshots/voluntarius/03.png");
  top: 0;
  left: 50%;
  transform: scale(0.9);
}

/* .voluntarius-04 {
  background-image: url("../img/screenshots/voluntarius/04.png");
  transform: scale(0.8);
} */

.voluntarius-logo {
  background-color: #fff;
  top: 10;
  /* left: 10; */
  right: 10;
}

.voluntarius-logo > .image {
  background-image: url("../img/logos/voluntarius.png");
}

/* .clasora-01 {
    background-image: url("../img/screenshots/clasora/04.png");
    transform: scale(0.8);
} */

.clasora-02 {
  background-image: url("../img/screenshots/clasora/02.png");
  top: -10;
  left: -10;
  transform: scale(0.9);
}

.clasora-03 {
  background-image: url("../img/screenshots/clasora/03.png");
  top: 20;
  left: 25%;
  transform: scale(0.9);
}

.clasora-04 {
  background-image: url("../img/screenshots/clasora/04.png");
  top: 0;
  left: 50%;
  transform: scale(0.9);
}

.clasora-logo {
  background-color: #fff;
  top: 10;
  /* left: 10; */
  right: 10;
}

.clasora-logo > .image {
  background-image: url("../img/logos/clasora.png");
}

.dashboard-01 {
  background-image: url("../img/screenshots/dashboard/01.png");
  top: 0;
  left: 30;
  transform: scale(0.8);
  z-index: 1;
}

.dashboard-02 {
  background-image: url("../img/screenshots/dashboard/02.png");
  top: 0;
  left: -10;
  transform: scale(0.9);
  z-index: 3;
}

/* .dashboard-03 {
  background-image: url("../img/screenshots/dashboard/03.png");
  top: 20;
  left: 25%;
  transform: scale(0.9);
} */

/* .dashboard-04 {
  background-image: url("../img/screenshots/dashboard/04.png");
  top: 0;
  left: 50%;
  transform: scale(0.9);
} */

.dashboard-05 {
  background-image: url("../img/screenshots/dashboard/05.png");
  top: 40%;
  left: 30;
  transform: scale(0.8);
  z-index: 4;
}

.dashboard-logo {
  background-color: #fff;
  top: 10;
  /* left: 10; */
  right: 10;
}

.dashboard-logo > .image {
  background-image: url("../img/logos/wowyow.png");
}

.email {
  background-image: url("../img/social/email.png");
}

.linkedin {
  background-image: url("../img/social/linkedin.png");
}

.instagram {
  background-image: url("../img/social/instagram.png");
}

.facebook {
  background-image: url("../img/social/facebook.png");
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: #9f9f9f;
}

::-webkit-scrollbar-thumb {
  background: #ededed;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #000;
}
